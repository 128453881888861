require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
})

const languages = require('./src/data/languages')

const serverName = 'https://nsssp-prod-backend.parallelpublicworks.com'

module.exports = {
  siteMetadata: {
    title: 'NSSSP',
    description: 'Application and LOR requests',
    keywords: 'gatsby,theme,carbon',
    drupalUrl: serverName,
    langs: languages
  },
  pathPrefix: `/gtc`,
  plugins: [
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'NSSSP',
        short_name: 'NSSSP',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#71AD50',
        display: 'browser',
        icon: './src/images/favicon.svg',
      },
    },
    {
      resolve: 'gatsby-source-drupal',
      options: {
        baseUrl: serverName,
        langs: languages,
        skipFileDownloads: true,
        basicAuth: {
          username: process.env.BASIC_AUTH_USERNAME,
          password: process.env.BASIC_AUTH_PASSWORD,
        }
      }
    },
    {
      resolve: 'gatsby-theme-carbon',
      options: {
        isSearchEnabled: false,
        iconPath: './src/images/favicon.svg',
        repository: {
          baseUrl:
            'https://github.com/carbon-design-system/gatsby-theme-carbon',
          subDirectory: '/packages/example',
        },
      },
    },
    {
      resolve: `gatsby-source-drupal-menu-links`,
      options: {
        baseUrl: serverName,
        apiBase: `jsonapi`,
        menus: ["main"],
        basicAuth: {
          username: process.env.BASIC_AUTH_USERNAME,
          password: process.env.BASIC_AUTH_PASSWORD,
        }
      },
    },
  ]
};
